import React from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { postPatientRecord, postEvaluation } from '../../actions/assessmentInfo';
import { createLoadingSelector } from '../../reducers/api';
import Box from '@material-ui/core/Box';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import VitalSignInfo from '../../components/Assessment/VitalSignInfo';

export default () => {
  const dispatch = useDispatch();
  const loading = useSelector(createLoadingSelector(['assessment/patient/post', 'assessment/evaluation/post']));

  const nextStep = async (value) => {
    try {
      await dispatch(postPatientRecord(value));
      await dispatch(postEvaluation());
      navigate(`/assessment/symptomEntry`);
    } catch (e) {
    }
  }

  const consult = async (value) => {
    try {
      await dispatch(postPatientRecord(value));
      navigate('/consult/patientApp/chooseCategory');
    } catch (e) {
    }
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>แบบประเมินสุขภาพ - MorOnline</title>
      </Helmet>
      <Box p={2}>
        <VitalSignInfo loading={loading} onSubmit={nextStep} onSkip={consult} />
      </Box>
    </Layout>
  );
}
